import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ResponsiveGrid from 'shared/components/ResponsiveGrid';
import { buildFunnelUrl } from 'static_pages/marketing_pages/helpers';
import DarkButton from 'system/components/Button/DarkButton';

const ProfessionalOrPrivateSection = () => {
  const { t } = useTranslation('static_pages');
  const router = useRouter();
  return (
    <>
      <Box maxWidth={{ xs: 600, md: 800, lg: 900 }} mx="auto">
        <SectionTitle mb={{ xs: 1, md: 2 }}>
          {t('homepage.areYouProfessionalSection.title')}
        </SectionTitle>
        <SectionSubTitle mb={{ xs: 3, md: 5 }}>
          {t('homepage.areYouProfessionalSection.subtitle')}
        </SectionSubTitle>
      </Box>
      <ResponsiveGrid
        min={300}
        max={500}
        gap={6.5}
        alignItems="start"
        justifyContent="center"
      >
        <Card
          image={{
            src:
              '/artifacts/images/homepage/professional-or-private/funnel-illustration.webp',
            height: 230,
            width: 350,
          }}
          title={t('homepage.areYouProfessionalSection.askUs.title')}
          description={t(
            'homepage.areYouProfessionalSection.askUs.description'
          )}
          button={{
            label: t('homepage.areYouProfessionalSection.askUs.cta'),
            link: buildFunnelUrl({ router }),
          }}
        />
        <Card
          image={{
            src:
              '/artifacts/images/homepage/professional-or-private/lunch-dinner.webp',
            height: 230,
            width: 410,
          }}
          title={t('homepage.areYouProfessionalSection.doYourself.title')}
          description={t(
            'homepage.areYouProfessionalSection.doYourself.description'
          )}
          button={{
            label: t('homepage.areYouProfessionalSection.doYourself.cta'),
            link: '/catering',
          }}
        />
      </ResponsiveGrid>
    </>
  );
};

interface CardProps {
  title: string;
  image: {
    src: string;
    width: number;
    height: number;
  };
  description: string;
  button: { label: string; link: string };
}
const Card = ({ image, title, description, button }: CardProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Image src={image.src} width={image.width} height={image.height} alt="" />

      <Title my={2}>{title}</Title>
      <Text mb={3} px={{ xs: 2, lg: 5 }}>
        {description}
      </Text>
      <Link href={button.link} passHref>
        <DarkButton component="a" rounded>
          {button.label}
        </DarkButton>
      </Link>
    </Box>
  );
};

const SectionTitle = styled(Typography)`
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  line-height: 172.5%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 36px;
  }
`;

const SectionSubTitle = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  line-height: 168%;
  text-align: center;
`;

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 41px;
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary['500']};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 24px;
  }
`;

const Text = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;

  text-align: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 16px;
  }
`;

export default ProfessionalOrPrivateSection;
