import { Box } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  min?: number;
  gap?: number;
  max?: number;
}

const ResponsiveGrid = styled(Box)<Props>`
  --min: ${({ min }) => min || 300}px;
  --gap: ${({ gap, theme }) => theme.spacing(gap || 2)}px;
  --max: ${({ max }) => (max ? `${max}px` : '1fr')};

  width: 'auto';
  display: grid;
  grid-gap: var(--gap);
  /* min() with 100% prevents overflow
  in extra narrow spaces */
  grid-template-columns: repeat(
    auto-fit,
    minmax(min(100%, var(--min)), var(--max))
  );
`;

export default ResponsiveGrid;
